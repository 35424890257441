<template>
  <div>
    <el-row class="processManage" v-if="flagList">
      <dataPbulic @dataPublic="dataPublic">
        <!-- 日期组件 -->
        <el-col :span="3">
          <el-select
            size="small"
            v-model="queryObj.selectType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ProcessType"
              :key="item.id"
              :label="$t(`Process.MyApplication.ProcessType.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            v-model="queryObj.status"
            placeholder="请选择审批状态"
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="$t(`Process.MyApplication.options.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col style="position: relative" :span="3.5">
            <el-input
              class="el-input-style"
              style="width: 240px"
              v-model.trim="dataArray.InputText"
              size="small"
              :placeholder="`请输入${placeholder}`"
              @keyup.enter.native="searchList"
            ></el-input>
            <el-select
              class="el-select-style"
              style="width: 100px; margin-left: 2px"
              size="small"
              placeholder="设备名称"
              v-model="dataArray.deviceName"
              @change="searchTypeChange"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
        </el-col>
        <!-- <el-col :span="3">
          <el-select style="width: 100%" v-model="queryObj.name" placeholder="名称" size="small" @change="changeName">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
        </el-col> -->
        <!-- <el-col :span="3">
          <el-input v-if="flagSerach" v-model="queryObj.askNum" size="small" :placeholder="`请输入${placeholder}`" @keyup.enter.native="searchList"></el-input>
          <el-input v-else v-model="queryObj.askName" size="small" :placeholder="`请输入${placeholder}`" @keyup.enter.native="searchList"></el-input>
        </el-col> -->
        <el-col :span="3">
          <el-button size="small" type="primary" @click="searchList">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </dataPbulic>
      <el-row :gutter="4" class="elRow">
        <el-col :span="3">
          <el-button-group>
            <el-button size="small" type="primary" @click="createFrom()">{{
              $t("public.New")
            }}</el-button>
            <el-button
              style="margin-left:0.5px;"
              size="small"
              :disabled="!hasSeleted"
              :type="backdataInfo"
              @click="backdataList"
              >{{ $t("Process.MyApplication.Revoke") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          :default-sort="{ prop: 'CREATE_TIME', order: 'descending' }"
          ref="multipleTable"
          :data="dictData"
          lazy
          size="mini"
          stripe
          tooltip-effect="dark"
          class="tableClass"
          @sort-change="sort"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column
            header-align="center"
            align="center"
            type="selection"
            width="60"
            :selectable="selectEnable"
          >
          </el-table-column>
          <el-table-column
            prop="ASK_NUM"
            sortable="custom"
            :label="$t('Process.MyApplication.ApplicationNo')"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.askNum }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ASK_NAME"
            sortable="custom"
            :label="$t('Process.MyApplication.Name')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span
                @click="backDetail(row)"
                style="color: #d38a08; cursor: pointer"
                >{{ row.askName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="ASK_TYPE"
            :label="$t('Process.MyApplication.ProcessTypes')"
            sortable="custom"
            width="180"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.askType == 1">
                {{ $t("Process.MyApplication.ProcessType.应用上架") }}
              </template>
              <template v-if="row.askType == 2">
                {{ $t("Process.MyApplication.ProcessType.应用下架") }}
              </template>
              <template v-if="row.askType == 3">
                {{ $t("Process.MyApplication.ProcessType.设备注册") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="STATUS"
            :label="$t('Process.MyApplication.ApprovalStatus')"
            width="180"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <el-link
                v-if="row.status == 1"
                @click="statusDetail(row)"
                type="primary"
              >
                {{ $t("Process.MyApplication.options.待审批") }}
              </el-link>
              <el-link
                v-if="row.status == 2"
                @click="statusDetail(row)"
                type="primary"
              >
                {{ $t("Process.MyApplication.options.审批中") }}
              </el-link>
              <el-link
                v-if="row.status == 3"
                @click="statusDetail(row)"
                type="primary"
              >
                {{ $t("Process.MyApplication.options.已通过") }}
              </el-link>
              <el-link
                v-if="row.status == 4"
                @click="statusDetail(row)"
                type="primary"
              >
                {{ $t("Process.MyApplication.options.已拒绝") }}
              </el-link>
              <el-link
                v-if="row.status == 5"
                @click="statusDetail(row)"
                type="primary"
              >
                {{ $t("Process.MyApplication.options.已废除") }}
              </el-link>
              <el-link
                v-if="row.status == 6"
                @click="statusDetail(row)"
                type="primary"
              >
                {{ $t("Process.MyApplication.options.已撤销") }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="CREATE_TIME"
            sortable="custom"
            :label="$t('Process.MyApplication.ApplicationTime')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">{{
              scope.row.createTime | dateformat
            }}</template>
          </el-table-column>
          <el-table-column
            prop="UPDATE_TIME"
            sortable="custom"
            :label="$t('Process.MyApplication.ApprovalTime')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.updateTime">
                {{ row.updateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <el-link
                v-if="row.status == 6 && row.askType != 3"
                style="color: #d38a08"
                @click="handleEdit(row)"
                type="text"
                size="small"
                >{{ $t("public.Editor") }}</el-link
              >
              <el-link
                v-if="row.status == 6 && row.askType == 3"
                style="color: #d38a08"
                @click="againSubmit(row)"
                type="text"
                size="small"
                >{{ $t("Process.MyApplication.Resubmit") }}</el-link
              >
              <el-link
                v-if="row.status == 1"
                style="color: #d38a08"
                @click="backdata(row)"
                type="text"
                size="small"
                >{{ $t("Process.MyApplication.Revoke") }}</el-link
              >
              <!-- <el-button
              style="color: #d38a08"
              v-if="row.status === '0'"
              @click="details(row, false)"
              type="text"
              size="small"
              >处理</el-button
            >
            <el-button
              style="color: #d38a08"
              v-if="row.status !== '0'"
              @click="details(row, true)"
              type="text"
              size="small"
              >详情</el-button
            >  -->
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        />
      </el-row>
    </el-row>
    <div v-else>
      <div class="title mt15"></div>
      <el-form
        :model="ruleFormlist"
        ref="ruleForm"
        label-width="100px"
        style="margin-left: 3%; height: 1000px"
        :rules="rules"
      >
        <el-form-item
          :label="$t('Process.MyApplication.Name') + '：'"
          prop="askName"
        >
          <el-input
            :placeholder="$t('Process.MyApplication.rules.askName')"
            v-model="ruleFormlist.askName"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('Process.MyApplication.ProcessTypes') + '：'"
          prop="askType"
        >
          <el-select
            style="width: 250px"
            v-model="ruleFormlist.askType"
            :disabled="askTypeFlag"
            :placeholder="$t('Process.MyApplication.rules.askType')"
            @change="changesType"
          >
            <el-option
              v-for="item in workflowType"
              :key="item.workflowType"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('public.ApplicationName') + '：'"
          prop="appName"
        >
          <el-input
            :disabled="flagDisable"
            v-model="ruleFormlist.appName"
            :placeholder="$t('Process.MyApplication.rules.appNames')"
            style="width: 250px"
          ></el-input>
          <el-button
            class="mar-class-8"
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @change="change"
            @click="toApplyFlag()"
            >{{ $t("Process.MyApplication.Apply") }}</el-button
          >
        </el-form-item>
        <el-form-item :label="$t('public.OperatingSystem') + '：'" prop="os">
          <el-input
            :disabled="flagDisable"
            v-model="ruleFormlist.os"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('public.ApplicationID') + '：'"
          prop="appPackage"
        >
          <el-input
            :disabled="flagDisable"
            v-model="ruleFormlist.appPackage"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('public.ApplicationVersion') + '：'"
          prop="appVersion"
        >
          <el-input
            :disabled="flagDisable"
            v-model="ruleFormlist.appVersion"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('Process.MyApplication.Introduction') + '：'">
          <el-input
            v-model="ruleFormlist.functionInt"
            :maxlength="300"
            :placeholder="$t('Process.MyApplication.rules.functionInt')"
            type="textarea"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('Process.MyApplication.ApplicationDepartment') + '：'"
        >
          <el-input
            v-model="ruleFormlist.groupName"
            :placeholder="$t('Process.MyApplication.rules.groupName')"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('Process.MyApplication.Telephone') + '：'">
          <el-input
            v-model="ruleFormlist.phone"
            :placeholder="$t('Process.MyApplication.rules.phone')"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="submitForm('ruleForm')" type="primary">{{
            $t("Process.MyApplication.SubmitApplication")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 名称详情 -->
    <Eldialog
      @handleClose="handleClose"
      :num="'320px'"
      :title="title"
      :dialogVisible="dialogVisible"
    >
      <detail-list :dictData="dataFrom" v-if="!clickNameFlag" />
      <deviceRegister v-if="clickNameFlag" :dictData="registerFrom" />
      <!-- <el-button style="float: right; margin:10px 0 0 0;" type="primary" @click="dialogVisible = false" size="mini">关闭</el-button> -->
    </Eldialog>
    <!-- 状态详情 -->
    <Eldialog
      :width="'1000px'"
      @handleClose="handleClose"
      :title="$t('Process.MyApplication.options.审批状态')"
      :dialogVisible="statusVisible"
    >
      <approvalStatus :dictData="statusData" />
      <el-button
        style="float: right; margin: 10px 0 0 0"
        type="primary"
        @click="statusVisible = false"
        size="mini"
        >{{ $t("Process.MyApplication.EndBtn") }}</el-button
      >
    </Eldialog>
    <!-- 选择应用 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('Process.MyApplication.ChooseMobileApp')"
      :btnTitle="$t('public.Join')"
      :flagbtn="true"
      :determineBtn="true"
      :cancelBtn="true"
      @determine="jiaru"
      :dialogVisible="applyFlag"
      :destroy-on-close="true"
      :num="'470px'"
    >
      <div style="width: 98%">
        <el-row :gutter="3" class="mt15">
          <el-col :span="6">
            <el-input
              size="small"
              @keyup.enter.native="appList1"
              :placeholder="$t('Process.MyApplication.Pleaseenter')"
              v-model="appList.search0"
              @change="changeSelect"
            ></el-input>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" size="small" @click="appList1">{{
              $t("public.Inquire")
            }}</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="24">
            <el-table
              :header-row-style="{ height: '30px' }"
              v-if="applyFlag"
              :data="appData.content"
              style="width: 100%; padding-left: 10px"
              stripe
            >
              <el-table-column header-align="center" align="center" width="60">
                <template v-slot="{ row }">
                  <el-radio-group @change="changeEvent(row)" v-model="radio">
                    <el-radio :label="row.id">{{ "" }}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="name"
                :label="$t('public.ApplicationName')"
                width="100"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="packageName"
                :label="$t('public.ApplicationID')"
                width="100"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="versionName"
                :label="$t('public.Version')"
                width="100"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="osTypeId"
                :label="$t('public.OperatingSystem')"
                width="80"
              >
                <template v-slot="{ row }">
                  <template v-if="row.osTypeId == 20"> Android </template>
                  <template v-if="row.osTypeId == 0"> / </template>
                  <template v-if="row.osTypeId == 10"> iOS </template>
                  <template v-if="row.osTypeId == 30"> Windows </template>
                  <template v-if="row.osTypeId == 40"> Linux </template>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row :gutter="16" class="mt15">
          <el-col :span="22">
            <p>
              共<b>{{ this.appData.content.length }}</b
              >条记录
            </p>
          </el-col>
          <!-- <el-col :span="2">
            <el-button style="marginleft: 50px" type="primary" size="small" @click="jiaru">加入</el-button>
          </el-col> -->
        </el-row>
      </div>
    </Eldialog>
    <!-- 提示消息组件 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>
<script>
import dataPbulic from "@/components/datePublic/index";
import dialogInfo from "@/components/promptMessage/index";
// import naviGation from '@/components/hearder/index'
import approvalStatus from "./components/approvalStatus";
import detailList from "./components/detail.vue";
import deviceRegister from "./components/deviceRegister.vue"; //设备注册申请详情
import Eldialog from "@/components/elDialog";
// import pagination from "../../../components/pagination/page.vue.vue";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    pagination,
    Eldialog,
    approvalStatus,
    detailList,
    deviceRegister,
    // naviGation,
    dialogInfo,
    dataPbulic,
  },

  data() {
    return {
      AppId: "",
      editAsktype: "",
      askTypeFlag:false, //流程状态禁用
      clickNameFlag: true, //名称详情弹框默认展示设备注册内容
      statusFlag: true,
      statusFlag1: true,
      visibaelFlag: false, //回调弹窗关闭
      outerVisible: false,
      titleInfo: "111",
      backdataInfo: "info",
      radio: "",
      length: 0,
      editorlist: [],
      // 遮罩全选
      flagDisable: false,
      isCheckShow: false,
      flagList: true, //新增编辑
      applyFlag: false, //选择应用
      dialogVisible: false, //详情弹框关闭
      statusVisible: false, //状态弹框
      title: "审批详情",
      content: "新增页面",
      page: {
        orderColume: "CREATE_TIME",
        orderRule: "desc",
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        // totalPages: 40, //总页数
        currentPage: 1,
      },
      queryObj: {
        name: "申请编号",
        status: "",
        startTime: "",
        endTime: "",
        selectType: "",
      },
      dataArray: {
        deviceName: "0",
        InputText: "",
      },
      ProcessType: [
        {
          value: "",
          label: "流程类型",
        },
        {
          value: "1",
          label: "应用上架",
        },
        {
          value: "2",
          label: "应用下架",
        },
        {
          value: "3",
          label: "设备注册",
        },
      ],
      options: [
        {
          value: "",
          label: "审批状态",
        },
        {
          value: "1",
          label: "待审批",
        },
        {
          value: "2",
          label: "审批中",
        },
        {
          value: "4",
          label: "已拒绝",
        },
        {
          value: "3",
          label: "已通过",
        },
        {
          value: "6",
          label: "已撤销",
        },
        {
          value: "5",
          label: "已废除",
        },
      ],
      placeholder: "申请编号",
      workflowType: [],
      workflowId: [],
      back: {
        ids: [""],
      },
      down: {
        type: "protected",
        path: "/模板文档/加固证明模板.docx",
      },
      up: {
        type: "protected",
        businessTyp: "",
        files: [""],
      },
      xiudata: {
        ids: [""],
        askName: "",
        askType: "",
        workflowId: "",
        content: "",
      },
      addlist: {
        askTypePre: "",
        askName: "",
        askType: "",
        workflowId: "",
        content: "",
      },
      multipleSelection: [],
      statusData: [],
      ruleFormlist: {
        appName: "",
        groupName: "",
        phone: "",
        inspectReport: "",
        inspectProve: "",
        os: "",
        askType: 1,
        functionInt:'',
        appPackage: "",
        appVersion: "",
        name:""
      },
      dictData: [],
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dataFrom: {},
      registerFrom: {}, //设备注册申请详情表单
      appData: {
        content: [],
        appName: "",
        mainActivity: "",
        versionName: "",
        osTypeId: "", //20  Android   10    iOS
        totalCount: "",
      },
      rules: {
        askName: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.askName"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: this.$t("Process.MyApplication.Error2"),
            trigger: "blur",
          },
        ],
        askType: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.askType"),
            trigger: "blur",
          },
        ],
        appName: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.appName"),
          },
        ],
        os: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.os"),
            trigger: "change",
          },
        ],
        appPackage: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.appPackage"),
            trigger: "change",
          },
        ],
        appVersion: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.appVersion"),
            trigger: "change",
          },
        ],
        inspectReport: [
          { required: true, message: "加固证明不能为空", trigger: "change" },
        ],
        inspectProve: [
          { required: true, message: "检测报告不能为空", trigger: "change" },
        ],
        useRange: [
          { required: true, message: "使用范围不能为空", trigger: "change" },
        ],
        useRange: [
          { required: true, message: "使用范围不能为空", trigger: "change" },
        ],
        functionInt: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.functionInt"),
            trigger: "change",
          },
        ],
        groupName: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.groupName"),
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: this.$t("Process.MyApplication.rules.phone"),
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      typeOptions: [
        {
          value: "0",
          label: "申请编号",
        },
        {
          value: "1",
          label: "名称",
        },
      ],
      getUserInfo: {},
      myHeaders: { "Content-Type": "multipart/form-data" },
      appList: {
        pageNumber: 1,
        rowNumber: 100000,
        search0: "",
      },
      selectData: null,
      // 应用列表获取的值
      fukey: "",
      flagSerach: true,
      fileSize: 0,
    };
  },

  computed: {
    hasSeleted() {
      return this.multipleSelection && this.multipleSelection.length;
    },
  },

  mounted() {
    this.obtainList(); //获取我的申请列表
    this.backlist();
  },

  methods: {
    searchTypeChange(val) {
      let dataName = this.typeOptions.find((item) => item.value == val);
      this.placeholder = dataName.label;
    },
    dataPublic(val) {
      this.queryObj.startTime = val.startTime;
      this.queryObj.endTime = val.endTime;
    },
    //排序
    sort(column) {
      console.log(column.order, column.prop, "----");
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.obtainList();
    },
    selectEnable(row, rowIndex) {
      if (row.status == 1) {
        return true;
      } else {
        return false;
      }
    },
    changeEvent(val) {
      console.log(val);
      this.selectData = { ...val };
    },
    jiaru() {
      // 加入
      this.AppId = this.selectData.id;
      this.ruleFormlist.os = this.selectData.osTypeId == 30 ? "Windows" : this.selectData.osTypeId == 20?'Android':this.selectData.osTypeId == 10?'iOS':this.selectData.osTypeId == 40 ? "Linux":'/';
      this.ruleFormlist.appPackage = this.selectData.packageName;
      this.ruleFormlist.appVersion = this.selectData.versionName;
      this.ruleFormlist.appName = this.selectData.name;
      this.ruleFormlist.groupName = this.selectData.department
        ? this.selectData.department
        : this.getUserInfo.manageGroupFullName;
      this.checkReportData();
      this.applyFlag = false;
    },
    // 检测报告和检测证明
    async checkReportData() {
      let params = {
        contentId: this.selectData.id,
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/checkReportData",
        params,
        true
      );
      if (res.data.inspectReport == null) {
        this.ruleFormlist.inspectProve = "";
        this.statusFlag = true;
      } else {
        this.statusFlag = false;
        let date = res.data.inspectReport.split("/");
        this.ruleFormlist.inspectProve = date[date.length - 1];
        this.uploadDta = res.data.inspectReport;
      }
      if (res.data.reinforceReport == null) {
        this.ruleFormlist.inspectReport = "";
        this.statusFlag1 = true;
      } else {
        this.statusFlag1 = false;
        let date = res.data.reinforceReport.split("/");
        this.ruleFormlist.inspectReport = date[date.length - 1];
        this.uploadDta1 = res.data.reinforceReport;
      }
    },
    changeSelect() {
      this.appList1();
    },
    async appList1() {
      var param = {
        appName: this.appList.search0,
        currentPage: 1,
        pageSize: 1000,
        startTime: "",
        // endTime: '',
        // orderColume: '',
        orderRule: "",
        type: this.ruleFormlist.askType + "",
      };
      const res = await this.$axios.post(
        "/httpServe/entApp/getAppList",
        param,
        true
      );
      // console.log(res.data.content)
      this.appData = res.data;
    },
    changesType(val) {
      let dataType = this.workflowType.find((item) => item.value == val);
      this.workflowId = dataType.workflowId;
      this.$set(this.ruleFormlist, "appName", "");
      this.$set(this.ruleFormlist, "os", "");
      this.$set(this.ruleFormlist, "appPackage", "");
      this.$set(this.ruleFormlist, "appVersion", "");
      this.radio = "";
    },
    searchList() {
      this.page.currentPage = 1;
      this.obtainList();
    },
    // 日期
    changeDate() {
      let start = new Date(this.queryObj.startTime).getTime();
      let end = new Date(this.queryObj.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    async obtainList() {
      // this.queryObj.startTime = getAnyMonth("-", -1);
      // this.queryObj.endTime = getDate();
      this.changeDate();
      //获取我的申请列表
      var param = {
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.queryObj.startTime,
        endTime: this.queryObj.endTime,
        askNum:
          this.dataArray.deviceName == 0
            ? this.dataArray.InputText
            : this.queryObj.askNum,
        askName:
          this.dataArray.deviceName == 1
            ? this.dataArray.InputText
            : this.queryObj.askName,
        askType: this.queryObj.selectType,
        status: this.queryObj.status,
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/getDataInfo",
        param,
        true
      );
      this.dictData = res.data.content;
      this.page.total = res.data.total;
    },
    async backlist() {
      //返回申请单位+电话接口 新增
      var param = {};
      const res = await this.$axios.post(
        "/httpServe/sysUser/getUserInfo",
        param,
        true
      );
      console.log(res);
      if (
        res.data.groupFullNameShowByIndex == 14 ||
        res.data.groupFullNameShowByIndex == 4
      ) {
        let data = res.data.manageGroupFullName.split("•");
        res.data.manageGroupFullName = data[data.length - 1];
        console.log(res.data.manageGroupFullName);
        this.getUserInfo = res.data;
        console.log(this.getUserInfo);
      } else {
        this.getUserInfo = res.data;
      }
    },
    // 更改输入框名称
    changeName(item) {
      if (item == "名称") {
        this.flagSerach = false;
        this.queryObj.askNum = "";
      } else {
        this.flagSerach = true;
        this.queryObj.askName = "";
      }
      this.placeholder = item;
    },
    async cehxiao(row) {
      if (row) {
        let data = [];
        data.push(row.id);
        var param = {
          ids: data,
        };
        const res = await this.$axios.post(
          "/httpServe/processApproval/revoke",
          param,
          true
        );
        if (res.code === 200) {
          this.$message.success(this.$t("Process.MyApplication.MessagePort"));
          this.editAsktype = "";
          this.outerVisible = false;
          this.flagList = true;
          this.searchList();
        }
      }
    },
    async cehxiaolist() {
      let data = [];
      console.log(this.multipleSelection);
      this.multipleSelection.forEach((item) => {
        data.push(item.id);
      });
      var param = {
        ids: data,
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/revoke",
        param,
        true
      );
      if (res.code === 200) {
        this.$message.success(this.$t("Process.MyApplication.MessagePort"));
        this.searchList();
      }
    },
    // 撤销接口
    backdata(row) {
      this.$confirm(
        this.$t("Process.MyApplication.Confirm2"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.cehxiao(row);
        })
        .catch(() => {
          return false;
        });
    },
    backdataList(row) {
      this.$confirm(
        this.$t("Process.MyApplication.Confirm2"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.cehxiaolist();
        })
        .catch(() => {
          return false;
        });
    },
    keyUp(e) {
      e.target.value = e.target.value.replace(
        /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
        ""
      );
    },
    // 只能输入汉字、英文、数字
    keydown(e) {
      e.target.value = e.target.value.replace(
        /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
        ""
      );
    },
    // 审批状态查询接口
    async statusline(data) {
      let arr = [];
      arr.push(data);
      var param = {
        ids: arr,
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/approveStatus",
        param,
        true
      );
      this.statusData = res.data;
    },
    // 下载模板接口
    async downline() {
      var param = {
        type: "protected",
        path: "/public/模板文档/加固证明模板.docx",
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(res.data);
    },
    //文件上传接口
    async changeUpload(file) {
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message.warning(`当前限制文件大小不能大于500M`);
        return false;
      }
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ["pdf", "PDF", "doc", "docx"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: "文件格式错误",
          type: "error",
          duration: 2000,
        });
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", this.up.type);
        form.append("fileType", "doc");
        form.append("moduleName", "protected");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        this.uploadDta = res.data.data[0];
        this.fileSize = file.size;
        this.$nextTick(() => {
          this.ruleFormlist.inspectProve = file.name;
        });
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    //文件上传接口  /processApproval/fileUp
    async changeUpload1(file) {
      if (file.size / (1024 * 1024) > 30) {
        // 限制文件大小
        this.$message.warning(`当前限制文件大小不能大于30M`);
        return false;
      }
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ["pdf", "PDF", "doc", "docx"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: "文件格式错误",
          type: "error",
          duration: 2000,
        });
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", this.up.type);
        form.append("fileType", "doc");
        form.append("moduleName", "protected");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        //路径
        this.uploadDta1 = res.data.data[0];
        this.$nextTick(() => {
          this.ruleFormlist.inspectReport = file.name;
        });
      }
    },
    handleAvatarSuccess1(response, file, fileList) {
      // this.ruleFormlist.inspectReport = file.name
    },
    handleAvatarSuccess2(response, file, fileList) {
      // this.ruleFormlist.inspectProve = file.name
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.backdataInfo = "primary";
      } else {
        this.backdataInfo = "info";
      }
    },
    //名称详情
    backDetail(val) {
      let arr = [];
      console.log(val.content, "val");
      arr = JSON.parse(val.content);
      this.title = this.$t("Process.MyApplication.titleDetail");
      if (val.askType == 3) {
        this.registerFrom = { ...val };
        this.registerFrom.content = JSON.parse(this.registerFrom.content);
        this.registerFrom.content.groupName =
          this.registerFrom.content.groupName.indexOf("•") !== -1
            ? this.registerFrom.content.groupName.split("•")[1]
            : this.registerFrom.content.groupName;
        this.clickNameFlag = true;
        console.log(this.registerFrom, "this.registerFrom");
      } else {
        this.clickNameFlag = false;
        if (arr.appName !== undefined) {
          this.dataFrom = {
            ids: val.ids,
            workflowId: val.workflowId,
            askName: val.askName,
            askType: val.askType,
            os: arr.os,
            appName: arr.appName,
            appVersion: arr.appVersion,
            appPackage: arr.appPackage,
            inspectProve: arr.inspectProve,
            // inspectProve:arr.inspectProve.filePath
            inspectReport: arr.inspectReport,
            // inspectReport: arr.inspectReport.filePath,
            useRange: arr.useRange,
            functionInt: arr.functionInt,
            groupName:
              arr.groupName.indexOf("•") !== -1
                ? arr.groupName.split("•")[1]
                : arr.groupName,
            phone: arr.phone,
          };
          console.log(this.dataFrom, "this.dataFrom");
        } else {
          this.dataFrom = { ...val };
          this.dataFrom.content.groupName =
            this.dataFrom.content.groupName.indexOf("•") !== -1
              ? this.dataFrom.content.groupName.split("•")[1]
              : this.dataFrom.content.groupName;
          console.log(this.dataFrom, "this.dataFrom");
        }
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.statusVisible = false;
      this.applyFlag = false;
      this.visibaelFlag = false;
    },
    statusDetail(val) {
      this.statusline(val.id);
      this.statusVisible = true;
    },
    //编辑
    handleEdit(val) {
      this.askTypeFlag = true
      console.log(val);
      this.editAsktype = val.askType;
      this.AppId = val.contentId;
      this.lctype(); //流程类型
      let arr = [];
      this.flagDisable = true;
      this.flagBtn = true;
      arr = JSON.parse(val.content);
      if (arr.appName !== undefined) {
        this.ruleFormlist = {
          id: val.id,
          workflowId: val.workflowId,
          askName: val.askName,
          askType: val.askType,
          os: arr.os,
          appName: arr.appName,
          appVersion: arr.appVersion,
          appPackage: arr.appPackage,
          inspectProve: arr.inspectProve.name,
          inspectProvePath: arr.inspectProve.path,
          inspectReport: arr.inspectReport.name,
          inspectReportPath: arr.inspectReport.path,
          useRange: arr.useRange,
          functionInt: arr.functionInt,
          groupName: arr.groupName,
          phone: arr.phone,
        };
      } else {
        this.ruleFormlist = { ...val };
        this.$set(this.ruleFormlist, "askType", val.askType);
      }
      this.content = "编辑页面";
      this.flagList = false;
    },
    //重新提交
    againSubmit(row) {
      this.$confirm(
        this.$t("Process.MyApplication.Confirm1"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      ).then(async () => {
        console.log(row, "row重新提交");
        console.log(row.content, "row.content");
        let data = [];
        data.push(row.id);
        var param = {
          ids: data,
          askName: row.askName,
          askType: row.askType,
          workflowId: row.workflowId,
          contentId: row.id,
          content: row.content,
        };
        const res = await this.$axios.post(
          "/httpServe/processApproval/editData",
          param,
          true
        );
        if (res.code === 200) {
          if (res.data == 1) {
            this.$message.success(
              this.$t("Process.MyApplication.MessagePort1")
            );
            this.editAsktype = "";
            this.obtainList(); //获取我的申请列表
          }
        }
      });
    },
    // 编辑确定
    async xiuline() {
      let content = {
        appName: this.ruleFormlist.appName,
        os: this.ruleFormlist.os,
        appPackage: this.ruleFormlist.appPackage,
        appVersion: this.ruleFormlist.appVersion,
        useRange: this.ruleFormlist.useRange,
        functionInt: this.ruleFormlist.functionInt,
        inspectProve: {
          name: this.ruleFormlist.inspectProve,
          path: this.ruleFormlist.inspectProvePath
            ? this.ruleFormlist.inspectProvePath
            : this.uploadDta,
        },
        inspectReport: {
          name: this.ruleFormlist.inspectReport,
          path: this.ruleFormlist.inspectReportPath
            ? this.ruleFormlist.inspectReportPath
            : this.uploadDta,
        },
        groupName: this.ruleFormlist.groupName,
        phone: this.ruleFormlist.phone,
      };
      let contentarr = JSON.stringify(content);
      let data = [];
      data.push(this.ruleFormlist.id);
      var param = {
        ids: data,
        askName: this.ruleFormlist.askName,
        askType: this.ruleFormlist.askType,
        workflowId: this.ruleFormlist.workflowId,
        contentId: this.AppId,
        content: contentarr,
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/editData",
        param,
        true
      );
      if (res.data == -1) {
        this.$message.error(this.$t("Process.MyApplication.Confirm3"));
        this.editAsktype = "";
        // this.outerVisible = false
        // this.flagList = true
        // this.searchList()
      }
      if (res.data == 1) {
        this.$message.success(this.$t("Process.MyApplication.MessagePort2"));
        this.editAsktype = "";
        this.outerVisible = false;
        this.flagList = true;
        this.searchList();
      }
    },
    //返回
    goBack() {
      this.flagList = true;
    },
    //新增
    createFrom() {
      this.askTypeFlag = false
      this.lctype(); //流程类型
      this.flagBtn = false;
      this.content = "新增页面";
      this.flagList = false;
      this.flagDisable = true;
      this.ruleFormlist.groupName = "";
      this.$set(this.ruleFormlist, "phone");
      this.$set(this.ruleFormlist,'askName','')
      this.ruleFormlist.phone = this.getUserInfo.phone;
      this.ruleFormlist.appName = "";
      this.ruleFormlist.os = "";
      this.ruleFormlist.functionInt = "";
      this.ruleFormlist.name = "";
      this.ruleFormlist.appPackage = "";
      this.ruleFormlist.appVersion = "";
      this.ruleFormlist.id = "";
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.obtainList();
    },
    // 新建提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleFormlist.id) {
            this.xiuline();
          } else {
            this.addlist1();
            this.goBack();
          }
        } else {
          return false;
        }
      });
    },
    // 新增接口
    async addlist1() {
      let content = {
        appName: this.ruleFormlist.appName,
        os: this.ruleFormlist.os,
        appPackage: this.ruleFormlist.appPackage,
        appVersion: this.ruleFormlist.appVersion,
        useRange: this.ruleFormlist.useRange,
        functionInt: this.ruleFormlist.functionInt,
        inspectProve: {
          name: this.ruleFormlist.inspectProve,
          path: this.uploadDta,
          // size:this.fileSize
        },
        inspectReport: {
          name: this.ruleFormlist.inspectReport,
          path: this.uploadDta1,
          //  size:this.fileSize1
        },
        groupName: this.ruleFormlist.groupName,
        phone: this.ruleFormlist.phone,
      };
      let contentarr = JSON.stringify(content);
      //新增申请
      var param = {
        askTypePre: this.ruleFormlist.askType == 1 ? "SJ" : "XJ",
        askName: this.ruleFormlist.askName,
        askType: this.ruleFormlist.askType,
        workflowId: this.workflowId + "",
        contentId: this.selectData.id,
        content: contentarr,
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/save",
        param,
        true
      );
      if (res.data == -1) {
        this.$message.error("当前应用已有审批中的流程");
        this.editAsktype = "";
        // this.outerVisible = false
        // this.flagList = true
        // this.searchList()
      }
      if (res.data == 1) {
        this.$message.success("新增成功");
        this.editAsktype = "";
        this.outerVisible = false;
        this.flagList = true;
        this.obtainList();
        this.ruleFormlist = {};
      }
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false;
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
    change() {
      this.$forceUpdate(); //其作用就是强制性刷新了一次
    },
    toApplyFlag() {
      this.applyFlag = true;
      this.appList1();
    },
    // /workFlow/getworkflowType 流程类型
    async lctype() {
      var param = {};
      const res = await this.$axios.post(
        "/httpServe/workFlow/getWorkflowType",
        param,
        true
      );
      let data = [];
      res.data.forEach((item) => {
        if (item.workflowType == 1) {
          this.$set(this.ruleFormlist, "askType", item.workflowType);
          this.workflowId = item.workflowId;
          data.push({
            workflowId: item.workflowId,
            value: item.workflowType,
            label: "应用上架",
          });
        }
        if (item.workflowType == 2) {
          this.$set(this.ruleFormlist, "askType", item.workflowType);
          this.workflowId = item.workflowId;
          data.push({
            workflowId: item.workflowId,
            value: item.workflowType,
            label: "应用下架",
          });
        }
        // if (item.workflowType == 3) {
        //   data.push({
        //     workflowId: item.workflowId,
        //     value: item.workflowType,
        //     label: '设备注册'
        //   })
        // }
      });
      data.forEach((item) => {
        if (item.value == 1) {
          this.$set(this.ruleFormlist, "askType", item.value);
          this.workflowId = item.workflowId;
        }
      });
      this.workflowType = data;

      console.log(this.workflowType);
      if (this.workflowType.length == 0) {
        this.$set(this.ruleFormlist, "askType", "");
      }
      if (this.editAsktype) {
        this.$set(this.ruleFormlist, "askType", this.editAsktype);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.processManage {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.mt15 {
  padding: 10px 10px;
}
.el-link.el-link--primary {
  color: #d38a08;
}
.el-select-style {
  position: absolute;
  left: 3px;
  top: 0;
  border: none;
  border-right: 1px solid #c0c4cc;
  margin-top: 5px;
  margin-right: 2px;
}
.mar-class-8 {
  margin-left: 10px;
}
.mt16 {
  padding: 10px 10px 0 20px;
}
::v-deep .el-select-style {
  .el-input__inner {
    border: 0;
    height: 23px;
    line-height: 23px;
  }
  .el-input--small .el-input__icon {
    line-height: 23px;
  }
}
::v-deep .el-input-style {
  .el-input__inner {
    padding-left: 110px;
  }
}

::v-deep .el-table__empty-text {
  width: 60% !important;
}
</style>