<template>
  <div>
    <el-form
      :model="dictData"
      ref="registerDetail"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('Process.MyApplication.Name')+'：'" prop="askName">
        {{ dictData.askName }}
      </el-form-item>
      <el-form-item :label="$t('Process.MyApplication.ProcessTypes')+'：'" prop="askName">
        <template v-if="dictData.askType == 1"> {{$t('Process.MyApplication.ProcessType.应用上架')}} </template>
        <template v-if="dictData.askType == 2"> {{$t('Process.MyApplication.ProcessType.应用下架')}} </template>
        <template v-if="dictData.askType == 3"> {{$t('Process.MyApplication.ProcessType.设备注册')}} </template>
      </el-form-item>
      <el-form-item :label="$t('public.DeviceName')+'：'" prop="askName">
        {{ dictData.content.deviceName }}
      </el-form-item>
      <el-form-item :label="$t('Process.MyApplication.DeviceBindingUser')+'：'" prop="askName">
        {{ dictData.content.loginUser }}
      </el-form-item>
      <el-form-item :label="$t('public.Section')+'：'" prop="askName">
        {{ dictData.content.groupName }}
      </el-form-item>
      <el-form-item :label="$t('Process.MyApplication.IMEIUDID')+'：'" prop="askName">
        {{ dictData.content.udid }}
      </el-form-item>
      <el-form-item :label="$t('public.OperatingSystem')+'：'" prop="askName">
        {{ dictData.content.os }}
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  components: {},
  mounted() {
    console.log(this.dictData, "dictData");
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>