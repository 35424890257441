<template>
  <el-table tooltip-effect="dark" stripe class="sort_table" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :data="dictData" style="width: 100%">
    <el-table-column :show-overflow-tooltip="true" header-align="center" align="center" prop="step" :label="$t('Process.MyApplication.ApprovalNode')" width="80">
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" header-align="center" align="center" prop="userName" :label="$t('Process.MyApplication.ApprovedBy')" width="200">
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" header-align="center" align="center" prop="opeator" :label="$t('Process.MyApplication.ActualApprover')" width="95">
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" header-align="center" align="center" prop="approveStatus" :label="$t('Process.MyApplication.ApprovalOpinions')" width="80">
      <template v-slot="{ row }">
        <template v-if="row.approveStatus == 0"> 申请 </template>
        <template v-if="row.approveStatus == 1"> 同意 </template>
        <template v-if="row.approveStatus == 2"> 拒绝 </template>
        <template v-if="row.approveStatus == 3"> 废除 </template>
      </template>
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" header-align="center" align="center" prop="approveMemo" :label="$t('Process.MyApplication.ApprovalDetails')">
    </el-table-column>
    <el-table-column :show-overflow-tooltip="true" header-align="center" align="center" :label="$t('Process.MyApplication.OperateTime')" width="240">
      <template v-slot="{ row }">
        <template v-if="row.approveStatus == 0">
          <template v-if="row.createTime">{{
            row.createTime | dateformat
          }}</template>
        </template>
        <template v-if="row.approveStatus == 1">
          <template v-if="row.updateTime">
            {{ row.updateTime | dateformat }}
          </template>
        </template>
        <template v-if="row.approveStatus == 2">
          <template v-if="row.updateTime">
            {{ row.updateTime | dateformat }}
          </template>
        </template>
        <template v-if="row.approveStatus == 3">
          <template v-if="row.updateTime">
            {{ row.updateTime | dateformat }}
          </template>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    dictData: {
      type: Array,
      default: []
    }
  }
}
</script>

<style>
</style>
