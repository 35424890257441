<template>
  <el-form
    :model="dictData"
    ref="applyDetail"
    label-width="150px"
    class="demo-ruleForm"
  > 
    <el-form-item :label="$t('Process.MyApplication.Name')+'：'" prop="askName">
      {{ dictData.askName }}
    </el-form-item>
    <el-form-item :label="$t('Process.MyApplication.ProcessTypes')+'：'">
      <template v-if="dictData.askType == 1"> {{$t('Process.MyApplication.ProcessType.应用上架')}} </template>
        <template v-if="dictData.askType == 2"> {{$t('Process.MyApplication.ProcessType.应用下架')}} </template>
        <template v-if="dictData.askType == 3"> {{$t('Process.MyApplication.ProcessType.设备注册')}} </template>
    </el-form-item>
    <el-form-item :label="$t('public.ApplicationName')+'：'" prop="appName">
      {{ dictData.appName }}
    </el-form-item>
    <el-form-item :label="$t('public.OperatingSystem')+'：'" prop="os">
      {{ dictData.os }}
    </el-form-item>
    <el-form-item :label="$t('public.ApplicationID')+'：'" prop="appPackage">
      {{ dictData.appPackage }}
    </el-form-item>
    <el-form-item :label="$t('public.ApplicationVersion')+'：'" prop="appVersion">
      {{ dictData.appVersion }}
    </el-form-item>
    <el-form-item :label="$t('Process.MyApplication.Introduction')+'：'" prop="functionInt">
      {{ dictData.functionInt }}
    </el-form-item>
    <el-form-item :label="$t('Process.MyApplication.ApplicationDepartment')+'：'" prop="groupName">
      {{ dictData.groupName }}
    </el-form-item>
    <el-form-item :label="$t('Process.MyApplication.Telephone')+'：'" prop="phone">
      {{ dictData.phone }}
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  methods: {
    downloads(type) {
      this.fileDowns(type);
    },
    // 下载
    fileDowns(type) {
      // console.log(this.dictData)
      if (type == "inspectReport") {
        let data = this.dictData.inspectProve.path.split(',')
        if(data.length == 2) {
          data.forEach((item,index) => {
            if(item.split('.')[1] == 'pdf') {
              this.uploadData(data[index])
            }
          });
        }else{
          this.uploadData(this.dictData.inspectProve.path)
        }
      } else {
          this.uploadData(this.dictData.inspectReport.path.split(',')[0])
      }
    },
   async uploadData(path) {
    //  console.log(path)
        var parma = {
          bucketName: "msp/public",
          path: path,
        };
        const res = await this.$axios.post(
          "/httpServe/sysFile/fileDown",
          parma,
          true
        );
        // console.log(res)
        if (res.data) {
          await this.$axios.downloadFile(res.data);
        }
    }
  },
};
</script> 

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>