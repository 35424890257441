<template>
  <!-- style="width: 100%;"  -->
  <div >
    <el-row :gutter="4" style="margin-bottom: 10px;">
      <el-col :span="3">
        <el-date-picker :clearable="false"
          style="width: 100%"
          v-model="queryObj.startTime"
          type="date"
          placeholder="请选择开始时间"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker :clearable="false"
          style="width: 100%"
          v-model="queryObj.endTime"
          type="date"
          placeholder="请选择结束时间"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <slot></slot>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryObj: {
        endTime: "",
        startTime: "",
      },
    };
  },
  mounted() {
    this.changeDate();
  },
  created() {
    this.changeDate();
    var getAnyMonth = function (symbol, n) {
      var symbol = symbol || "-";
      var nowDate = new Date();
      nowDate.setMonth(nowDate.getMonth() + n);
      var year = nowDate.getFullYear();
      var month = nowDate.getMonth() + 1;
      var day = nowDate.getDate();
      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + month : month;
      return year + symbol + month + symbol + day;
    }; //当前日期
    var getDate = function () {
      var date = new Date();
      var year = date.getFullYear();
      var mon = date.getMonth() + 1;
      var day = date.getDate();
      return (
        year +
        "-" +
        (mon < 10 ? "0" + mon : mon) +
        "-" +
        (day < 10 ? "0" + day : day) + ' 23:23:59'
      );
    };
    this.$set(this.queryObj, "startTime", getAnyMonth("-", -1));
    this.$set(this.queryObj, "endTime", getDate());
  },
  methods: {
    // 日期
    changeDate() {
      let start = new Date(this.queryObj.startTime).getTime();
      let end = new Date(this.queryObj.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
      this.$emit("dataPublic", this.queryObj);
    },
  },
};
</script>

<style>
</style>